import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { getAds } from '../../../list/selectors'
import { getPaginationParams } from '../../selectors'
import { getInfo } from 'SRC/modules/users/detail/selectors'
import { Links } from 'SRC/modules/common/components/Pagination'
import { getCurrentSubCategory, getParentCategory } from 'SRC/modules/categories/selectors'

export const UserAds = ({paginationParams, ads, user, currentSubcategory, currentParentCategory}) => {
  const pageCount = Math.ceil(
    (paginationParams.totalCount) / paginationParams.countPerPage
  )
  if (pageCount === 0) {
    return null
  }
  const userTypeSeo = user ? user.type.seo : ''
  const username = user ? user.username : ''
  const currentPage = Number(paginationParams.currentPage)
  const isFirstPage = currentPage === 1
  const isLastPage = currentPage === pageCount
  const parentCategory = currentParentCategory ? currentParentCategory.seo : 'sve-kategorije'
  const category = currentSubcategory ? currentSubcategory.seo : 'all'
  const baseUrl = {href: '/user', as: `/${userTypeSeo}/${username}`}

  const queryParams = {
    username: username,
    userType: userTypeSeo,
    type: 'all',
    category,
    parentCategory
  }
  const prevUrl = {
    href: {
      pathname: baseUrl.href,
      query: {
        ...queryParams,
        page: currentPage - 1
      }
    },
    as: `${baseUrl.as}/${parentCategory}/${category}/all/${currentPage - 1}`
  }
  const nextUrl = {
    href: {
      pathname: baseUrl.href,
      query: {
        ...queryParams,
        page: currentPage + 1
      }
    },
    as: `${baseUrl.as}/${parentCategory}/${category}/all/${currentPage + 1}`
  }
  const pages = []
  for (let i = 1; i <= pageCount; i++) {
    pages.push({
      number: i,
      isCurrent: currentPage === i,
      href: {
        pathname: baseUrl.href,
        query: {
          ...queryParams,
          page: i
        }
      },
      as: `${baseUrl.as}/${parentCategory}/${category}/all/${i}`
    })
  }

  if (!ads.length) return null

  return (
    <Links
      isFirstPage={isFirstPage}
      isLastPage={isLastPage}
      prevUrl={prevUrl}
      nextUrl={nextUrl}
      pages={pages}
    />
  )
}

const mapStateToProps = state => ({
  ads: getAds(state),
  paginationParams: getPaginationParams(state),
  user: getInfo(state),
  currentSubcategory: getCurrentSubCategory(state),
  currentParentCategory: getParentCategory(state)
})

UserAds.propTypes = {
  ads: PropTypes.array.isRequired,
  user: PropTypes.shape({
    username: PropTypes.string.isRequired,
    type: PropTypes.shape({
      seo: PropTypes.string.isRequired
    })
  }),
  currentSubcategory: PropTypes.shape({
    seo: PropTypes.string.isRequired
  }),
  currentParentCategory: PropTypes.shape({
    seo: PropTypes.string.isRequired
  }),
  paginationParams: PropTypes.shape({
    currentPage: PropTypes.number.isRequired,
    countPerPage: PropTypes.number.isRequired,
    totalCount: PropTypes.number.isRequired
  }).isRequired
}

UserAds.defaultProps = {
  ads: []
}

export default connect(mapStateToProps)(UserAds)
