import React from 'react'
import { connect } from 'react-redux'
import { getAds } from 'SRC/modules/ads/list/selectors'
import { getPaginationParams } from 'SRC/modules/ads/pagination/selectors'
import { adsTypes } from 'Core/constants'
import { Links } from 'SRC/modules/common/components/Pagination'
import PropTypes from 'prop-types'

const HomeFree = ({paginationParams, ads, filterValues}) => {
  const pageCount = Math.ceil(paginationParams.totalCount / paginationParams.countPerPage)
  if (pageCount === 0) {
    return null
  }
  const currentPage = Number(paginationParams.currentPage)
  const currentType = paginationParams.adsType
  const isFirstPage = currentPage === 1 ? currentType === adsTypes.paid : false
  const isLastPage = currentPage === pageCount ? currentType === adsTypes.free : false
  const baseUrl = {href: '/', as: '/naslovna'}
  const prevPageNumber = currentPage === 1 ? currentPage : currentPage - 1
  const prevPageAdsType = currentPage === 1 ? adsTypes.paid : adsTypes.free

  const filterValuesNew = {}
  for (let property in filterValues) {
    if (typeof filterValues[property] === 'number' || typeof filterValues[property] === 'string') {
      filterValuesNew[property] = filterValues[property]
    } else {
      filterValuesNew[property] = JSON.stringify(filterValues[property])
    }
  }

  const prevUrl = {
    href: {
      pathname: baseUrl.href,
      query: filterValuesNew
        ? {...filterValuesNew, page: prevPageNumber, type: prevPageAdsType}
        : {page: prevPageNumber, type: prevPageAdsType}
    },
    as: {pathname: `${baseUrl.as}/${prevPageAdsType}/${prevPageNumber}`, query: filterValuesNew ? {...filterValuesNew} : null}
  }
  const nextPageNumber = currentType === adsTypes.paid ? currentPage : currentPage + 1
  const nextUrl = {
    href: {
      pathname: baseUrl.href,
      query: filterValuesNew
        ? {...filterValuesNew, page: nextPageNumber, type: adsTypes.free}
        : {page: nextPageNumber, type: adsTypes.free}
    },
    as: {pathname: `${baseUrl.as}/${adsTypes.free}/${nextPageNumber}`, query: filterValuesNew ? {...filterValuesNew} : null}
  }
  const freeAdsPages = []
  for (let i = 1; i <= pageCount; i++) {
    freeAdsPages.push({
      number: i + 1,
      isCurrent: currentType === adsTypes.free && i === currentPage,
      href: {
        pathname: baseUrl.href,
        query: filterValuesNew
          ? {...filterValuesNew, page: i, type: adsTypes.free}
          : {page: i, type: adsTypes.free}
      },
      as: {
        pathname: `${baseUrl.as}/${adsTypes.free}/${i}`,
        query: filterValuesNew ? {...filterValuesNew} : null
      }
    })
  }

  const paidAdsPages = [{
    number: 1,
    isCurrent: currentType === adsTypes.paid,
    href: {
      pathname: baseUrl.href,
      query: filterValuesNew
        ? {...filterValuesNew, page: 1, type: adsTypes.paid}
        : {page: 1, type: adsTypes.paid}
    },
    as: {
      pathname: `${baseUrl.as}/${adsTypes.paid}/1`,
      query: filterValuesNew ? {...filterValuesNew} : null
    }
  }]

  if (!ads.length) return null

  return (
    <Links
      isFirstPage={isFirstPage}
      isLastPage={isLastPage}
      prevUrl={prevUrl}
      nextUrl={nextUrl}
      pages={[...paidAdsPages, ...freeAdsPages]}
    />
  )
}

const mapStateToProps = (state) => ({
  ads: getAds(state),
  paginationParams: getPaginationParams(state),
  filterValues: {}
})

HomeFree.propTypes = {
  ads: PropTypes.array.isRequired,
  paginationParams: PropTypes.shape({
    currentPage: PropTypes.number.isRequired,
    countPerPage: PropTypes.number.isRequired,
    paidCount: PropTypes.number.isRequired,
    freeCount: PropTypes.number.isRequired,
    totalCount: PropTypes.number.isRequired,
    adsType: PropTypes.oneOf([
      adsTypes.paid,
      adsTypes.all,
      adsTypes.free
    ]).isRequired
  }).isRequired,
  filterValues: PropTypes.shape()
}

HomeFree.defaultProps = {
  ads: []
}

export default connect(mapStateToProps)(HomeFree)
