import { globalOptions } from 'src/core/constants'

export const getPages = pages => {
  const maxPaginationLinks = globalOptions.maxPaginationLinks
  const currentPage = pages.find(page => page.isCurrent)
  const currentPageNumber = currentPage ? currentPage.number : 1
  let pageNumbers = [1]
  const hasLeftDots = currentPageNumber > maxPaginationLinks - 2
  const hasRightDots = currentPageNumber <= pages.length - (maxPaginationLinks - 2) + 1
  let spliceIndex = 0
  if (hasLeftDots && hasRightDots) {
    spliceIndex = 1
    pageNumbers = [1, 2, 3]
    pageNumbers.push('...')
    if (!pageNumbers.includes(currentPageNumber - 2)) { pageNumbers.push(currentPageNumber - 2); spliceIndex++ }
    if (!pageNumbers.includes(currentPageNumber - 1)) { pageNumbers.push(currentPageNumber - 1); spliceIndex++ }
    pageNumbers.push(currentPageNumber, currentPageNumber + 1, currentPageNumber + 2)
  } else if (hasLeftDots && !hasRightDots) {
    pageNumbers = [1, 2, 3]
    pageNumbers.push('...')
    if (!pageNumbers.includes(currentPageNumber - 2)) { pageNumbers.push(currentPageNumber - 2); spliceIndex++ }
    if (!pageNumbers.includes(currentPageNumber - 1)) { pageNumbers.push(currentPageNumber - 1); spliceIndex++ }
    pageNumbers.push(currentPageNumber, currentPageNumber + 1, currentPageNumber + 2)
    for (let i = currentPageNumber; i <= pages.length; i++) pageNumbers.push(i)
  } else if (!hasLeftDots && hasRightDots) {
    for (let i = 2; i <= maxPaginationLinks - 1; i++) pageNumbers.push(i)
    pageNumbers.push('...')
  } else {
    if (pages.length > 1) for (let i = 2; i <= pages.length; i++) pageNumbers.push(i)
  }

  const filteredPages = pages.filter(page => pageNumbers.includes(page.number))
  console.log('pageNumbers', pageNumbers)
  if (hasLeftDots) filteredPages.splice(spliceIndex, 0, {isDots: true})
  if (hasRightDots) filteredPages.splice(filteredPages.length, 0, {isDots: true})

  return filteredPages
}
