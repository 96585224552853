import React from 'react'
import { connect } from 'react-redux'
import { getAds } from 'SRC/modules/ads/list/selectors'
import { getPaginationParams } from 'SRC/modules/ads/pagination/selectors'
import { adsTypes } from 'Core/constants'
import { Links } from 'SRC/modules/common/components/Pagination'
import PropTypes from 'prop-types'

const HomeAll = ({paginationParams, ads, filterValues}) => {
  const pageCount = Math.ceil(paginationParams.totalCount / paginationParams.countPerPage)
  if (pageCount === 0) {
    return null
  }

  const currentPage = Number(paginationParams.currentPage)
  const currentType = paginationParams.adsType
  const isFirstPage = currentPage === 1
  const isLastPage = currentPage === pageCount
  const baseUrl = {href: '/', as: '/naslovna'}

  const prevPageNumber = currentPage === 1 ? currentPage : currentPage - 1

  const filterValuesNew = {}
  for (let property in filterValues) {
    if (typeof filterValues[property] === 'number' || typeof filterValues[property] === 'string') {
      filterValuesNew[property] = filterValues[property]
    } else {
      filterValuesNew[property] = JSON.stringify(filterValues[property])
    }
  }

  const prevUrl = {
    href: {
      pathname: baseUrl.href,
      query: filterValuesNew
        ? {...filterValuesNew, page: prevPageNumber, type: adsTypes.all}
        : {page: prevPageNumber, type: adsTypes.all}
    },
    as: {pathname: `${baseUrl.as}/${adsTypes.all}/${prevPageNumber}`, query: filterValuesNew ? {...filterValuesNew} : null}
  }
  const nextPageNumber = currentPage + 1
  const nextUrl = {
    href: {
      pathname: baseUrl.href,
      query: filterValuesNew
        ? { ...filterValuesNew, page: nextPageNumber, type: adsTypes.all }
        : { page: nextPageNumber, type: adsTypes.all }
    },
    as: {pathname: `${baseUrl.as}/${adsTypes.all}/${nextPageNumber}`, query: filterValuesNew ? {...filterValuesNew} : null}
  }
  const pages = []
  for (let i = 1; i <= pageCount; i++) {
    pages.push({
      number: i,
      isCurrent: currentType === adsTypes.all && i === currentPage,
      href: {
        pathname: baseUrl.href,
        query: filterValuesNew
          ? {...filterValuesNew, page: i, type: adsTypes.all}
          : {page: i, type: adsTypes.all}
      },
      as: {
        pathname: `${baseUrl.as}/${adsTypes.all}/${i}`,
        query: filterValuesNew ? {...filterValuesNew} : null
      }
    })
  }

  if (!ads.length) return null

  return (
    <Links
      isFirstPage={isFirstPage}
      isLastPage={isLastPage}
      prevUrl={prevUrl}
      nextUrl={nextUrl}
      pages={pages}
    />
  )
}

const mapStateToProps = (state) => ({
  ads: getAds(state),
  paginationParams: getPaginationParams(state),
  filterValues: {}
})

HomeAll.propTypes = {
  ads: PropTypes.array.isRequired,
  paginationParams: PropTypes.shape({
    currentPage: PropTypes.number.isRequired,
    countPerPage: PropTypes.number.isRequired,
    paidCount: PropTypes.number.isRequired,
    freeCount: PropTypes.number.isRequired,
    totalCount: PropTypes.number.isRequired,
    adsType: PropTypes.oneOf([
      adsTypes.paid,
      adsTypes.all,
      adsTypes.free
    ]).isRequired
  }).isRequired,
  filterValues: PropTypes.shape()
}

HomeAll.defaultProps = {
  ads: []
}

export default connect(mapStateToProps)(HomeAll)
