import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import Link from 'next/link'
import { getIsMobileDevice } from 'SRC/modules/common/selectors'

const Next = ({
  isLastPage,
  href,
  as,
  scroll,
  shallow,
  isMobileDevice
}) => (
  !isLastPage && !isMobileDevice
    ? <li className={`ads-pagination__item`}>
      <Link href={href} as={as} scroll={scroll} shallow={shallow}>
        <a className={'ads-pagination__item-link'}>sledeća</a>
      </Link>
    </li>
    : ''
)

const mapStateToProps = state => ({
  isMobileDevice: getIsMobileDevice(state)
})

Next.propTypes = {
  href: PropTypes.any.isRequired,
  as: PropTypes.any.isRequired,
  isLastPage: PropTypes.bool,
  scroll: PropTypes.bool.isRequired,
  shallow: PropTypes.bool.isRequired,
  isMobileDevice: PropTypes.string.isRequired
}

Next.defaultProps = {
  isLastPage: false,
  scroll: true,
  shallow: false
}

export default connect(mapStateToProps)(Next)