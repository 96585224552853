import { globalOptions } from 'src/core/constants'

export const getPages = pages => {
  const maxPaginationLinks = globalOptions.maxPaginationLinksMobile
  const currentPage = pages.find(page => page.isCurrent)
  const currentPageNumber = currentPage ? currentPage.number : 1

  let pageNumbers = []

  if (currentPageNumber > Math.ceil(maxPaginationLinks / 2)) {

    const half = Math.floor(maxPaginationLinks / 2)

    const start = currentPageNumber - half
    const end = currentPageNumber + half

    console.log('start end', start, end)

    for (let i = start; i <= end; i++) {
      pageNumbers.push(i)
    }
  }

  if (currentPageNumber <= Math.ceil(maxPaginationLinks / 2)) {
    for (let i = 0; i < maxPaginationLinks; i++) {
      pageNumbers.push(currentPageNumber === i + 1 ? currentPageNumber : i + 1)
    }
  }

  const filteredPages = pages.filter(page => pageNumbers.includes(page.number))
  console.log('pageNumbers', pageNumbers)

  return filteredPages
}
