import PropTypes from 'prop-types'
import React from 'react'
import Link from 'next/link'
import { getAdThumbnailUrl } from 'SRC/modules/ads/shared/functions'

import {
  ItemCardViewDescription,
  ItemImage,
  ItemPlace,
  ItemPrice,
  ItemTime
} from '../../../components/Item'
import { adsListViews } from 'Core/constants'

export const Item = ({ ad }) => {
  const img = getAdThumbnailUrl(ad)

  const parentCategorySeo = ad && ad.parentCategories && ad.parentCategories.length ? ad.parentCategories[0].seo : null

  let hrefUrl = `/ad?parentCategory=${parentCategorySeo}&category=${ad.category.seo}&ad=${ad.seo}`
  let asUrl = `/${parentCategorySeo}/${ad.category.seo}/${ad.seo}`

  if (parentCategorySeo === ad.category.seo) {
    hrefUrl = `/ad?parentCategory=${parentCategorySeo}&ad=${ad.seo}`
    asUrl = `/${parentCategorySeo}/${ad.seo}`
  }

  const hidePriceType = ad.priceTo || ad.priceFrom

  return (
    <div className={`oglasi-item-list oglasi-item-list-${ad.category.seo}`}>
      <ItemImage
        category={ad.category.seo}
        seo={ad.seo}
        hrefUrl={hrefUrl}
        asUrl={asUrl}
        payed={ad.isPaid}
        className='oglasi-item-list-img'
        img={img}
        title={ad.titleCompiled}
      />{'\n'}
      <div className='oglasi-item-list-content'>
        <Link href={hrefUrl} as={asUrl}>
          <a className='oglasi-item-heading'>
            <h3>{ad.titleCompiled || ''}</h3>
          </a>
        </Link>
        {'\n'}
        <ItemCardViewDescription
          specs={ad.specifications ? ad.specifications.sort((spec1, spec2) => spec1.weightCatalog - spec2.weightCatalog).slice(0, 3) : []}
          compatibilities={ad.compatibilities ? ad.compatibilities : []}
          service={ad.service ? ad.service : null}
          serviceType={ad.serviceType ? ad.serviceType : null}
          description={ad.description}
        />
        {'\n'}
        <ItemPrice
          newPrice={ad.price}
          oldPrice={ad.oldPrice}
          priceFrom={ad.priceFrom}
          priceTo={ad.priceTo}
          priceOnRequest={ad.priceOnRequest}
          priceDaily={ad.priceDaily}
          priceMonthly={ad.priceMonthly}
          pricePeriod={ad.category.pricePeriod}
          promotion={ad.akcija}
          type='list'
          priceType={!hidePriceType ? ad.priceType : null}
          priceSpecification={ad.priceSpecification}
        />{'\n'}
        <ItemPlace location={ad.city && ad.city.name} style={adsListViews.LIST} />
        <ItemTime time={ad.activated} style={adsListViews.LIST} />
      </div>
    </div>
  )
}

Item.propTypes = {
  ad: PropTypes.shape({
    id: PropTypes.number.isRequired,
    isPaid: PropTypes.bool.isRequired,
    price: PropTypes.number.isRequired,
    oldPrice: PropTypes.number,
    priceFrom: PropTypes.number,
    priceTo: PropTypes.number,
    priceDaily: PropTypes.number,
    priceMonthly: PropTypes.number,
    priceOnRequest: PropTypes.bool,
    priceType: PropTypes.number.isRequired,
    priceSpecification: PropTypes.shape({
      value: PropTypes.string
    }),
    seo: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    titleCompiled: PropTypes.string.isRequired,
    specifications: PropTypes.array.isRequired,
    compatibilities: PropTypes.array.isRequired,
    service: PropTypes.object.isRequired,
    serviceType: PropTypes.object.isRequired,
    media: PropTypes.array,
    category: PropTypes.shape({
      seo: PropTypes.string.isRequired,
      pricePeriod: PropTypes.bool
    }).isRequired,
    parentCategories: PropTypes.arrayOf(PropTypes.shape({
      seo: PropTypes.string
    })),
    akcija: PropTypes.number,
    pro: PropTypes.bool,
    brand: PropTypes.object,
    product: PropTypes.object,
    city: PropTypes.object,
    activated: PropTypes.string
  }).isRequired
}
