import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { getAds } from '../../../list/selectors'
import { getPaginationParams } from '../../selectors'
import { getParentCategory, getCurrentSubCategory } from 'SRC/modules/categories/selectors'
import { getCurrentGroup } from 'SRC/modules/groups/selectors'
import { Links } from 'SRC/modules/common/components/Pagination'
import { adsTypes } from 'Core/constants'

export const Category = ({paginationParams, ads, currentParentCategory, currentSubcategory, currentGroup}) => {
  const pageCount = Math.ceil(
    (paginationParams.totalCount) / paginationParams.countPerPage
  )
  if (pageCount === 0) return null

  const currentPage = Number(paginationParams.currentPage)
  const isFirstPage = currentPage === 1
  const isLastPage = currentPage === pageCount

  const parentCategory = currentParentCategory
  const category = currentSubcategory || currentGroup

  let isSelfContainedParentCategory = false

  if (parentCategory && parentCategory.isSelfContainedParentCategory) isSelfContainedParentCategory = true

  const baseUrl = {
    href: parentCategory ? `/category` : `/`,
    as: parentCategory ? `/${parentCategory.seo}${category ? `/${category.seo}` : ''}` : `/`
  }

  if (isSelfContainedParentCategory) baseUrl.as = parentCategory ? `/${parentCategory.seo}/` : `/`

  const prevUrl = {
    href: {
      pathname: baseUrl.href,
      query: {
        parentCategory: parentCategory && parentCategory.seo,
        page: currentPage - 1,
        type: adsTypes.all
      }
    },
    as: `${baseUrl.as}/${adsTypes.all}/${currentPage - 1}`
  }

  const nextUrl = {
    href: {
      pathname: baseUrl.href,
      query: {
        parentCategory: parentCategory && parentCategory.seo,
        page: currentPage + 1,
        type: adsTypes.all
      }
    },
    as: `${baseUrl.as}/${adsTypes.all}/${currentPage + 1}`
  }

  if (parentCategory && category && !isSelfContainedParentCategory) {
    prevUrl.href.query.category = category.seo
    nextUrl.href.query.category = category.seo
  }

  const pages = []
  for (let i = 1; i <= pageCount; i++) {
    const page = {
      number: i,
      isCurrent: currentPage === i,
      href: {
        pathname: baseUrl.href,
        query: {
          parentCategory: parentCategory && parentCategory.seo,
          page: i,
          type: adsTypes.all
        }
      },
      as: `${baseUrl.as}/${adsTypes.all}/${i}`
    }

    if (parentCategory && category && !isSelfContainedParentCategory) {
      page.href.query.category = category.seo
    }

    pages.push(page)
  }
  

  if (!ads.length) return null

  return (
    <Links
      isFirstPage={isFirstPage}
      isLastPage={isLastPage}
      prevUrl={prevUrl}
      nextUrl={nextUrl}
      pages={pages}
    />
  )
}

const mapStateToProps = state => ({
  ads: getAds(state),
  paginationParams: getPaginationParams(state),
  currentParentCategory: getParentCategory(state),
  currentSubcategory: getCurrentSubCategory(state),
  currentGroup: getCurrentGroup(state)
})

Category.propTypes = {
  ads: PropTypes.array.isRequired,
  currentGroup: PropTypes.shape({
    seo: PropTypes.string.isRequired
  }),
  currentParentCategory: PropTypes.shape({
    seo: PropTypes.string.isRequired
  }),
  currentSubcategory: PropTypes.shape({
    seo: PropTypes.string.isRequired
  }),
  paginationParams: PropTypes.shape({
    currentPage: PropTypes.number.isRequired,
    countPerPage: PropTypes.number.isRequired,
    totalCount: PropTypes.number.isRequired
  }).isRequired
}

Category.defaultProps = {
  ads: []
}

export default connect(mapStateToProps)(Category)
