import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import Link from 'next/link'
import { getIsMobileDevice } from 'SRC/modules/common/selectors'

const Prev = ({
  isFirstPage, 
  href, 
  as, 
  scroll, 
  shallow,
  isMobileDevice
}) => (
  !isFirstPage && !isMobileDevice
    ? <li className={`ads-pagination__item`}>
      <Link href={href} as={as} scroll={scroll} shallow={shallow}>
        <a className={'ads-pagination__item-link'}>prethodna</a>
      </Link>
    </li>
    : ''
)

const mapStateToProps = state => ({
  isMobileDevice: getIsMobileDevice(state)
})

Prev.propTypes = {
  isFirstPage: PropTypes.bool,
  as: PropTypes.any.isRequired,
  href: PropTypes.any.isRequired,
  scroll: PropTypes.bool.isRequired,
  shallow: PropTypes.bool.isRequired,
  isMobileDevice: PropTypes.string.isRequired
}

Prev.defaultProps = {
  isFirstPage: false,
  scroll: true,
  shallow: false
}

export default connect(mapStateToProps)(Prev)
